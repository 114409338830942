<template>
    <v-card
        style="background: white; font-family: Montserrat; border: 1px solid gray; border-radius: 10px !important;"
        class="elevation-0 mt-8"
        :height="cardheight"
        width="344"
        rounded="10"
    >
        <v-img :src="jh_img" width="155px" class="mx-auto mt-8" style="border-radius: 0px !important;"></v-img>
        <v-card-text>
            <v-row class="mt-3 ml-1 amount">
                <v-col v-if="freq_treatment" cols="7">
                    <span style="color: #46C3B2; font-size: large;"><sup>$</sup></span>
                    <span class="premium">{{primaryPremium}} </span>
                    <span class="main-frequency">{{ main_frequency }}<sup><span style="color:#46C3B2">*</span></sup></span>
                </v-col>
                <v-col v-else cols="12">
                    <span style="color: #46C3B2; font-size: large;"><sup>$</sup></span>
                    <span class="premium">{{primaryPremium}} </span>
                    <span class="main-frequency">{{ main_frequency }}<sup><span style="color:#46C3B2">*</span></sup></span>
                </v-col>

                <v-col v-if="freq_treatment" cols="5">
                    <sup style="color: #666666; font-size: xx-small;">$</sup>
                    <span class="secondary-premium" style="color: #666666">{{ secondaryPremium }}</span>
                    <span class="secondary-premium"> {{ secondary_frequency }}<sup><span style="color:#46C3B2">*</span></sup></span>
                </v-col>
            </v-row>
            <v-card-actions
                @click="show = !show"
            >
                <v-btn
                color="q-secondary lighten-2"
                text
                class="text-subtitle-2"
                >
                    <span style="text-align: left; font: normal normal bold 17px/35px Montserrat; letter-spacing: 0px; color: #005952; opacity: 1;">
                        John Hancock Benefits
                    </span>
                </v-btn>

                <v-btn
                color="q-secondary lighten-2"
                icon
                >
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
            </v-card-actions>

            <v-expand-transition>
                <div v-show="show">
                <v-card-text>
                    <v-list flat>
                        <v-list-item
                            class="mt-n5"
                            v-for="(msg, i) in jh_card_info.messages"
                            :key="i"
                        >
                            <v-list-item-icon class="mr-0">
                                <v-icon color="q-primary">mdi-circle-small</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="text-caption text-wrap" style="color: #005851; font-family: Montserrat !important" v-html="msg"></v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                </div>
            </v-expand-transition>
            <v-btn
                color="q-secondary"
                depressed
                class="get-started px-3"
                block
                @click="launchEapp"
                :disabled="fetchingQuote"
                :style="show ? 'margin-top: 81px;' : 'margin-top: 44px;'"
            >
                Continue
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import quilityMixin from "@/mixins/quilityMixin";

export default {
    name: "JHQuoteCard",
    mixins: [quilityMixin],
    data: () => ({
        jh_img: null,
        show: false
    }),
    mounted(){
        this.jh_img = this.getImgUrl(this.jh_card_info.logo)
        this.show = !this.isMobile
    },
    computed: {
        jhData(){
            return this.quoteData.quotes.find(q => q.Carrier === "John Hancock")
        },
        monthlyPremium(){
            return this.formatNumber(2,2, this.jhData.Premium)
        },
        cardheight() {
            return this.show ? '480' : '323'
        },
        dailyPremium(){
            return this.formatNumber(2,2, (this.jhData.Premium / 30))
        },
        primaryPremium(){
            return this.freq_treatment ? this.dailyPremium : this.monthlyPremium
        },
        secondaryPremium(){
            return this.freq_treatment ? this.monthlyPremium : this.dailyPremium
        },
    },
    methods: {
        async launchEapp(){
            // qlt cta split event
            let client = await this.$split_client.latest
            client.track('anonymous', 'form.click.cta.jh')

            if(this.otp_treatment === true){
                this.$store.commit('updateSelectedProduct', 'JH')
                this.$router.push({name: 'Verify'});
            } else {
                // only tracking for corp dtc at the moment
                if(this.site === 'QUILITY'){
                    this.$amplitude_client.track('JH_e-app', this.amp_event_data)
                }

                this.gotoEapp()
            }
        },
        async gotoEapp() {
            // jh cta split event
            let client = await this.$split_client.latest

            this.$store.commit('updateEnteringEApp', true)
            this.$store.commit('updateSelectedProduct', 'JH')
            this.eApp_dialog = true;
            const parameters = this.$route.params;
            const phone_clean = this.form.phone_number.replace('(','').replace(')','')

            const d = new Date(this.form.dob)
            let dob_m = '' + (d.getMonth() + 1)
            let dob_d = '' + d.getDate()
            let dob_y = d.getFullYear()

            if(dob_m.length < 2){
                dob_m = '0' + dob_m
            }

            if(dob_d.length < 2){
                dob_d = '0' + dob_d
            }

            const param_premium = this.formatNumber(2,2, this.jhData.Premium)
            const queryParam = {
                firstName: this.form.first_name,
                lastName: this.form.last_name,
                dob: `${dob_m}-${dob_d}-${dob_y}`,
                gender: this.form.gender,
                state: this.form.state_id,
                phone: phone_clean,
                coverageAmount: new Intl.NumberFormat().format(this.form.coverage_amount),
                coverageLength: this.form.coverage_plan.substring(0, 2),
                smoker: this.form.tobacco_used === 'Yes' ? 'Smoker' : 'NonSmoker',
                healthClass: 'Excellent',
                estimateMonthly: param_premium,
                email: this.form.email,
                applicantId: this.zohoid,
                salesSource: 'ain',
                salesSourceVariation: 'quility',
                productType: 'lft',
                agentEmail: this.agent.agentEmail,
                agentLicense: this.agent.agentLicenses,
                agentLicenseState: this.form.state_id,
                agentName: this.agent.agentName,
                agentPhone: this.agent.agentPhone,
                agentAddress: this.agent.agentAddress,
                agentCity: this.agent.agentCity,
                agentState: this.agent.agentState,
                agentZip: this.agent.agentZip,
                agentPayrollID: this.agent.agentPayrollID,
                customSkin: 'quility',
                quility_site: (this.site === 'OC') ? 'oc' : 'quility',
                token: process.env.VUE_APP_JH_TOKEN,
            }

            if(this.agent.agentPayrollID == null){
                queryParam.agentId = this.site === 'OC' ? process.env.VUE_APP_OCAGENTID : process.env.VUE_APP_QAGENTID
                queryParam.agentPayrollID = this.site === 'OC' ? process.env.VUE_APP_OCAGENTPAYROLL : process.env.VUE_APP_QAGENTPAYROLL
            }

            let query_string = Object.keys(queryParam).map(function(key) {
                return key + '=' + queryParam[key]
            }).join('&')

            const redirect_url = process.env.VUE_APP_JH_EAPP_URL + 'processor/intake?' + query_string
            const lead_info = {
                'premium': this.jh_premium,
                'zoho_id': this.zohoid,
                'product': this.jh_product,
                'disposition': 'RAQ Apply Now',
            }

            try {
                await this.updateLeadProductPremium(parameters.form_data, lead_info, 'JH')

                client.track('anonymous', 'form.eapp.start')
                if(this.site === 'OC'){
                    client.track('anonymous', 'form.eapp.ocdtc.jh.start')
                } else {
                    client.track('anonymous', 'form.eapp.jh.start')
                }
                // kill split instance
                await this.$split_client.delete()

                window.location.href = redirect_url
            } catch (error) {
                // kill split instance
                await this.$split_client.delete()

                console.error('jhQuoteCard->gotoEapp->error',error)
                this.error_dialogue = true
                this.eApp_dialog = false
            }
        },
    },
}
</script>


<style lang="scss" scoped>
$text--secondary: #9B9B9B;

.amount {
    font-weight: 800;
}

.get-started {
    color: #fff !important;
    font-family: Montserrat, SemiBold;
    font-weight: 600;
    font-size: 0.8rem;

    @media only screen and (max-width: 486px) {
        & {
            font-size: 0.7rem !important;
            img {
                width: 12px;
            }
        }
    }
}

.premium {
    text-align: left;
    font: normal normal 800 20px/34px Montserrat;
    letter-spacing: 0px;
    color: #46C3B2;
}

.main-frequency {
    text-align: left;
    font: normal normal bold 15px/34px Montserrat;
    letter-spacing: 0px;
    color: #46C3B2;
}

.secondary-premium {
    text-align: left;
    font: normal normal normal 12px/34px Montserrat;
    letter-spacing: 0px;
    color: #666666;
}
</style>